<script setup lang="ts"></script>

<template>
  <svg
    id="Raggruppa_8252"
    data-name="Raggruppa 8252"
    xmlns="http://www.w3.org/2000/svg"
    width="69.931"
    height="56.194"
    viewBox="0 0 69.931 56.194"
  >
    <path
      id="Tracciato_1049"
      data-name="Tracciato 1049"
      d="M2577.068,286.271c.129-.386.24-.779.392-1.156a5.117,5.117,0,0,1,4.884-3.194c3.481-.011,6.962,0,10.442,0q10.443,0,20.886,0a2.877,2.877,0,0,1,.808.073.921.921,0,0,1,.7,1.036.932.932,0,0,1-.852.916,4.633,4.633,0,0,1-.749.021H2582.66c-2.337,0-3.544,1.2-3.544,3.517q0,15.94,0,31.88c0,2.27,1.223,3.486,3.508,3.486H2633.2c2.328,0,3.531-1.2,3.532-3.531q0-9.25,0-18.5a3.857,3.857,0,0,1,.019-.681,1.015,1.015,0,0,1,2.012.015,3.417,3.417,0,0,1,.017.613q0,9.318,0,18.637a5.151,5.151,0,0,1-5.517,5.495q-25.255,0-50.508,0c-3.044,0-4.765-1.3-5.615-4.237a.526.526,0,0,0-.071-.116Z"
      transform="translate(-2577.068 -268.705)"
      fill="#363636"
    />
    <path
      id="Tracciato_1050"
      data-name="Tracciato 1050"
      d="M2610.746,326.8c0-1.978-.008-3.957,0-5.935A5.119,5.119,0,0,1,2616,315.6q5.968-.026,11.937,0a5.09,5.09,0,0,1,5.279,5.173q.078,6.1,0,12.211a5.137,5.137,0,0,1-5.221,5.106q-5.969.021-11.937,0a5.129,5.129,0,0,1-5.311-5.35C2610.742,330.757,2610.746,328.779,2610.746,326.8Zm19.205,8.583a2.9,2.9,0,0,0,1.219-2.4c.02-4.1.039-8.192-.005-12.288a2.972,2.972,0,0,0-2.974-3.04q-6.211-.059-12.422,0a2.985,2.985,0,0,0-2.964,2.977c-.026,4.141-.018,8.283,0,12.425a2.9,2.9,0,0,0,1.216,2.337,8.647,8.647,0,0,1,4.292-4.959,6.069,6.069,0,0,1-.546-9,5.962,5.962,0,0,1,8.266-.147,5.754,5.754,0,0,1,1.9,3.961,5.985,5.985,0,0,1-2.278,5.137C2628.278,332.166,2628.885,332.869,2629.951,335.383Zm-11.874-9.681a3.911,3.911,0,1,0,3.855-3.961A3.92,3.92,0,0,0,2618.077,325.7Zm9.8,10.314c-.379-2.4-3.288-4.451-6.163-4.353-2.62.089-5.405,2.241-5.6,4.353Z"
      transform="translate(-2604.602 -296.235)"
      fill="#363636"
    />
    <path
      id="Tracciato_1051"
      data-name="Tracciato 1051"
      d="M2766.811,232.931c-.387-.667-.684-1.254-1.061-1.784a.947.947,0,0,0-.685-.253c-2.23-.018-4.46-.011-6.69-.011-.182,0-.364,0-.546,0a1.025,1.025,0,1,1-.017-2.039c1.41-.019,2.821-.007,4.232-.007h2.641c-.184-1.211-.422-2.363-.524-3.528a14.815,14.815,0,1,1,4.8,9.994,1.5,1.5,0,0,0-.908-.307c-3.345-.022-6.689,0-10.034-.033a1.669,1.669,0,0,1-1.122-.452.977.977,0,0,1,.8-1.562c.634-.047,1.273-.014,1.911-.014Zm24.964-8.621a12.814,12.814,0,1,0-12.809,12.822A12.849,12.849,0,0,0,2791.776,224.31Z"
      transform="translate(-2723.921 -209.476)"
      fill="#363636"
    />
    <path
      id="Tracciato_1052"
      data-name="Tracciato 1052"
      d="M2635.529,450.214H2658.6c.227,0,.456-.012.682.01a1.009,1.009,0,0,1,.134,2,2.929,2.929,0,0,1-.678.039q-23.2,0-46.407,0a2.939,2.939,0,0,1-.678-.039,1.009,1.009,0,0,1,.131-2c.226-.022.455-.01.682-.01Z"
      transform="translate(-2604.674 -406.311)"
      fill="#363636"
    />
    <path
      id="Tracciato_1053"
      data-name="Tracciato 1053"
      d="M2635.529,472.679H2658.6c.228,0,.457-.012.682.01a1.009,1.009,0,0,1,.133,2,2.942,2.942,0,0,1-.678.039q-23.2,0-46.407,0a2.942,2.942,0,0,1-.678-.038,1.009,1.009,0,0,1,.133-2c.225-.022.455-.01.682-.01Z"
      transform="translate(-2604.674 -424.679)"
      fill="#363636"
    />
    <path
      id="Tracciato_1054"
      data-name="Tracciato 1054"
      d="M2768.213,405.288c3.343,0,6.686-.012,10.029.017a1.705,1.705,0,0,1,1.086.394.951.951,0,0,1-.492,1.566,2.926,2.926,0,0,1-.809.068q-9.858.005-19.717,0c-.114,0-.227,0-.341,0-.736-.028-1.192-.425-1.19-1.036s.458-1.007,1.2-1.008Q2763.1,405.282,2768.213,405.288Z"
      transform="translate(-2724.007 -369.578)"
      fill="#363636"
    />
    <path
      id="Tracciato_1055"
      data-name="Tracciato 1055"
      d="M2768.153,427.755c3.366,0,6.731-.01,10.1.015a1.692,1.692,0,0,1,1.041.349.949.949,0,0,1-.4,1.59,2.523,2.523,0,0,1-.8.09q-9.927.007-19.853,0c-.114,0-.228,0-.341,0a1.051,1.051,0,0,1-1.114-1.043,1.023,1.023,0,0,1,1.139-1c3-.009,6,0,9.006,0Z"
      transform="translate(-2724.008 -387.948)"
      fill="#363636"
    />
    <path
      id="Tracciato_1056"
      data-name="Tracciato 1056"
      d="M2845.958,279.144a7.567,7.567,0,0,1-1.563-.839c-.936-.824-1.791-1.742-2.664-2.636a1.028,1.028,0,1,1,1.444-1.439c.8.775,1.6,1.551,2.357,2.366.382.411.618.357.987-.017,2.121-2.153,4.265-4.283,6.4-6.419a3.722,3.722,0,0,1,.5-.464,1.008,1.008,0,0,1,1.424,1.393,2.462,2.462,0,0,1-.363.4q-3.324,3.328-6.649,6.655A3.308,3.308,0,0,1,2845.958,279.144Z"
      transform="translate(-2793.141 -258.539)"
      fill="#363636"
    />
  </svg>
</template>

<style scoped></style>
